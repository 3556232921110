.rdp {
   @apply !m-0 lg:m-2;
   --rdp-cell-size: auto;
   --rdp-caption-font-size: 18px;
   --rdp-accent-color: #1a62ed;
   --rdp-background-color: rgb(0 80 235 / 0.2);
   --rdp-accent-color-dark: #0048d4;
   --rdp-background-color-dark: rgb(0 80 235 / 0.2);
   --rdp-outline: 2px solid var(--rdp-accent-color);
   --rdp-outline-selected: 3px solid var(--rdp-accent-color);
   --rdp-selected-color: #fff;
}

.rdp-table,
.rdp-month {
   @apply !w-full !max-w-full;
}

.rdp-caption {
   @apply pb-2;
}
.rdp-head_cell {
   @apply w-28 p-1 text-zinc-500 relative ltr:left-3 rtl:right-3 ltr:!text-left rtl:!text-right;
}

.rdp-cell {
   @apply !px-0;
}

.rdp-nav_button {
   @apply mx-1 p-2 hover:!bg-sta-primary/10 focus:!bg-sta-primary/20;
}

.rdp-button_reset.rdp-button.rdp-nav_button.rdp-nav_button_previous {
   @apply rtl:rotate-180;
}

.rdp-button_reset.rdp-button.rdp-nav_button.rdp-nav_button_next {
   @apply rtl:rotate-180;
}

.rdp-button_reset.rdp-button.rdp-day {
   @apply w-full rounded-full py-2 hover:bg-sta-primary/10 focus:!bg-sta-primary/20;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected {
   @apply border-2 bg-sta-primary font-semibold !text-white focus:!bg-sta-primary;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start,
.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end {
   @apply border-none bg-sta-primary text-sta-subdued max-w-full;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start {
   @apply rtl:!rounded-l-none rtl:!rounded-r-md ltr:!rounded-r-none ltr:!rounded-l-md;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end {
   @apply rtl:rounded-l-md rtl:rounded-r-none ltr:rounded-l-none ltr:rounded-r-md;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start.rdp-day_range_end {
   @apply !rounded-md;
}

.rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle {
   @apply rounded-none border-none bg-sta-primary/50 text-sta-subdued max-w-full focus:!bg-sta-primary/50;
}

.rdp-day_selected * {
   @apply !text-white;
}
