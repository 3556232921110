/* Arabic fonts */
@font-face {
   font-family: 'BR';
   src: url('/fonts/arb/arb-bold.ttf');
   font-weight: bold 700;
   font-display: swap;
}

@font-face {
   font-family: 'BR';
   src: url('/fonts/arb/arb-normal.ttf');
   font-weight: 300 400 normal;
   font-display: swap;
}

@font-face {
   font-family: 'Harir';
   src: url('/fonts/harir/bold.otf');
   font-weight: bold 700;
   font-display: swap;
}
@font-face {
   font-family: 'Harir';
   src: url('/fonts/harir/normal.otf');
   font-weight: 300 400 normal;
   font-display: swap;
}

@font-face {
   font-family: 'Saudi Sans';
   src: url('/fonts/saudiSans/SaudiSans-VF.woff2');
   font-weight: 400 500 600 700;
   font-display: swap;
}

@font-face {
   font-family: 'Saudi Serif';
   src: url('/fonts/saudiSerif/SaudiSerif-VF.woff2');
   font-weight: 400 500 600 700;
   font-display: swap;
}
