/* Make clicks pass-through */
#nprogress {
   pointer-events: none;
}

#nprogress .bar {
   background: theme('colors.sta.primary');
   position: fixed;
   z-index: 1031;
   top: 0;
   left: 0;

   width: 100%;
   height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
   display: block;
   position: absolute;
   right: 0px;
   width: 100px;
   height: 100%;
   box-shadow: 0 0 10px theme('colors.sta.primary'), 0 0 5px theme('colors.sta.primary');
   opacity: 1;

   -webkit-transform: rotate(3deg) translate(0px, -4px);
   -ms-transform: rotate(3deg) translate(0px, -4px);
   transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
   overflow: hidden;
   position: relative;
}

.nprogress-custom-parent #nprogress .bar {
   position: absolute;
}
