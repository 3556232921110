* {
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
   scroll-behavior: smooth;
}

body {
   opacity: 1 !important;
}

.covid-message {
   [dir='rtl'] & {
      transform: rotateY(180deg);
   }
}

.safe-area {
   padding-bottom: env(safe-area-inset-bottom);
   /* padding-bottom:10px; */
   /* @screen sm {
  padding-bottom:0;

  } */
}
.swiper-slide {
   @apply flex justify-center;
   height: auto !important;
}

.swiper-arrow {
   @apply h-12 w-12 rounded-full border cursor-pointer;
   @apply hidden md:flex items-center justify-center border-opacity-50;

   &.primary {
      @apply border-gray-50 hover:bg-gray-50 text-primary-light;
      &:disabled {
         @apply hover:bg-white  cursor-not-allowed opacity-50;
      }
   }
   &.secondary {
      @apply border-white hover:bg-white text-white hover:text-primary-light hover:bg-opacity-75;
      &:disabled {
         @apply hover:bg-transparent cursor-not-allowed opacity-50;
      }
   }
   &.white {
      @apply border-white border-2 hover:bg-black text-white hover:bg-opacity-75;
      &:disabled {
         @apply hover:bg-black hover:bg-opacity-100 cursor-not-allowed opacity-50;
      }
   }
}

.heading {
   @apply lg:text-headings text-4xl font-bold grow block;
   letter-spacing: -0.9px;
}
.subheading {
   @apply text-primary-light grow mb-0;
}

.pagination-bullet {
   @apply w-5 rounded-full bg-primary-color bg-opacity-20 inline-block;
   height: 3px;
   &.active {
      @apply bg-opacity-100;
   }
}
.pagination-bullet-white {
   @apply w-2 h-2 rounded-full  border-[1px] border-white border-opacity-30 inline-block;
   &.active {
      @apply bg-opacity-100 bg-white;
   }
}
.pagination-top-section {
   @apply w-5 rounded-full  border-[1px] border-white xs:border-gray-200 border-opacity-30 inline-block;
   &.active {
      @apply bg-opacity-100 bg-white xs:border-black;
   }
}
img {
   text-indent: -9999px;
}

.react-datepicker-popper {
   z-index: 20 !important;
}

.react-select {
   input {
      @apply focus:ring-0;
   }
}

body {
   [dir='rtl'] & {
      .prose ul > li {
         padding-left: 0 !important;
         padding-right: 1.75em;
      }
      .prose ul > li:before {
         left: unset !important;
         right: 0.25em;
      }
   }
}
.text-white {
   color: white;
}
.section-radius {
   border-bottom-right-radius: 50% 75px;
   border-bottom-left-radius: 50% 75px;
}
.is-rtl {
   direction: ltr !important;
}

.bottom-sheet {
   [role='dialog'] {
      z-index: 100;
      background: none;
   }

   div:first-child {
      z-index: 80;
   }
}
.react-datepicker__current-month {
   @apply !text-sta-primary !font-normal !mt-2 !mb-4;
}
div .react-datepicker__header {
   @apply bg-white border-b-0 !rounded-tr-default !rounded-tl-default;
   @apply font-saudiSans;
}

div .react-datepicker__today-button {
   @apply font-saudiSans;
}

.datepicker-shadowless .react-datepicker {
   @apply !shadow-none bg-[#FBFAFA];
   &__header {
      @apply bg-[#FBFAFA];
   }
}
div .react-datepicker {
   @apply rounded-default border-0 px-1;
   box-shadow: 0px 14px 44px -1px rgba(0, 0, 0, 0.11);
   &__day-name,
   &__day {
      @apply small:!w-9 small:!h-9 !w-8 !h-8 font-normal;
      &:not(&__day--disabled) {
         @apply font-bold;
      }
   }
   &__day {
      @apply text-sm focus:outline-none focus:ring-1 focus:ring-offset-1 mb-2 mx-[3px];
      @apply focus:ring-sta-primary hover:ring-1 hover:ring-sta-primary hover:ring-offset-1;
      @apply !rounded-full border-2 border-transparent inline-flex justify-center items-center;

      &--disabled {
         @apply cursor-not-allowed hover:!ring-transparent !text-[#ccc];
      }
   }

   &__day--selected,
   &__day--in-selecting-range,
   &__day--in-range,
   &__month-text--selected,
   &__month-text--in-selecting-range,
   &__month-text--in-range,
   &__quarter-text--selected,
   &__quarter-text--in-selecting-range,
   &__quarter-text--in-range,
   &__year-text--selected,
   &__year-text--in-selecting-range,
   &__year-text--in-range,
   &__month-text--keyboard-selected,
   &__quarter-text--keyboard-selected,
   &__year-text--keyboard-selected {
      @apply !bg-gray-100 border-sta-primary !text-sta-primary font-bold;
   }

   &__today-button {
      @apply p-0 border-0 bg-transparent cursor-default;
   }
   &__day--outside-month {
      @apply pointer-events-none !font-normal !text-[#ccc] !border-transparent !bg-transparent !mb-0 !ring-transparent;
   }
   &__day--keyboard-selected {
      background: none;
      color: black;
   }
}
.react-datepicker {
   @apply bg-white;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
   @apply bg-gray-50;
}

.flex-list {
   .react-datepicker {
      @apply !flex;
   }
}

.bottom-sheet-datepicker {
   .react-datepicker {
      @apply flex flex-col;
      &__day,
      &__day-name {
         @apply small:!w-12 small:!h-12 !w-10 !h-10;
      }
   }
}
.react-datepicker {
   .has-active-slot:not(&__day--outside-month) {
      @apply relative;
      &::after {
         content: '';
         @apply w-[5px] h-[5px] z-10 rounded-full bg-sta-light absolute bottom-1;
      }
   }
   .range-selected-active {
      &.react-datepicker__day--disabled {
         @apply !opacity-70 !cursor-not-allowed;
      }
      @apply !bg-sta-primary !text-white !rounded-none !border-none focus:!ring-0 hover:!ring-0 hover:!ring-offset-0  hover:!ring-transparent focus:!ring-transparent focus:!ring-offset-0 !mx-0 !w-[38px] small:!w-[42px];
      &-first,
      &-last {
         &.react-datepicker__day--disabled {
            @apply !opacity-70 !cursor-not-allowed;
         }
         @apply !bg-sta-primary !text-white !border-none focus:!ring-0 hover:!ring-0 hover:!ring-offset-0  hover:!ring-transparent focus:!ring-transparent focus:!ring-offset-0 !mx-0 !w-[38px] small:!w-[42px];
      }
      &-first {
         @apply rtl:!rounded-l-none ltr:!rounded-r-none !border-none;
      }
      &-last {
         @apply rtl:!rounded-r-none ltr:!rounded-l-none !border-none;
      }
   }
   .range-selected-active-first.range-selected-active-last {
      @apply !rounded-full;
   }
}

.react-datepicker__day--keyboard-selected:hover {
   @apply !bg-sta-primary !text-white;
}
[data-rsbs-header] {
   @apply !bg-[#f8f8f8] !rounded-t-xl !shadow-none;
}
[data-rsbs-footer] {
   @apply relative z-[100] bg-white;
}
[data-rsbs-content] {
   @apply !bg-[#f8f8f8];
}
.content-vibile {
   [data-rsbs-scroll],
   [data-rsbs-content] {
      @apply overflow-visible;
   }
}

#chart iframe:not(:last-child) {
   display: none !important;
}

.scrollbar {
   &::-webkit-scrollbar {
      @apply w-1.5 rounded-xl;
   }

   &::-webkit-scrollbar-track {
      @apply rounded-xl bg-[#E6E6E6];
   }

   &::-webkit-scrollbar-thumb {
      @apply rounded-xl bg-[#CCCCCC];
   }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
   display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
}

.body-overlay {
   position: relative;
   &::after {
      content: '';
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.2;
   }
}

.react-datepicker-wrapper {
   @apply w-full;
}
